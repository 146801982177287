<template>
  <div class="sub-header">
    <div class="modal-overlay" v-if="showModal">
      <div class="modal-container">
        <purchaseModal
            @showModal = 'changeShowModal'
        />
      </div>
    </div>
    <div class="sub-header__wrap container flex">
      <div class="sub-header__left flex">
        <h1 class="sub-header__title main-title mx-3">
          {{ title }}
        </h1>
        <div v-if="showBlock && $route.params.type !== 'car-park'" class="sub-header__search-block">
          <input
            v-model="search"
            class="sub-header__search main-input"
            type="text"
            placeholder="Поиск .."
            @input="filterDatabase()"
          />
          <img class="sub-header__search-icon" src="@/assets/png/search-icon.png" alt="" />
        </div>
        <div v-if="$route.params.type === 'counterparties'" class="sub-header__search-block">
          <select v-model="type" class="sub-header__select main-input" @change="onChangeType">
            <option value="">Выберите лицо</option>
            <option value="individual">Физическое</option>
            <option value="entity">Юридическое</option>
          </select>
        </div>
      </div>
      <div class="car-tabs flex" v-if="$route.params.type === 'car-park'">
        <button
          class="sub-header__btn-tabs"
          :class="{
            'sub-header__active-tabs': $route.params.id === 'transports',
          }"
          @click="$router.push('/database/car-park/transports')"
          >
          Автомобили
        </button>
        <button
          class="sub-header__btn-tabs"
          :class="{
            'sub-header__active-tabs': $route.params.id === 'insurance',
          }"
          @click="$router.push('/database/car-park/insurance')"
          >
          Страхование
        </button>
        <button
          class="sub-header__btn-tabs"
          :class="{
            'sub-header__active-tabs': $route.params.id === 'repair',
          }"
          @click="$router.push('/database/car-park/repair')"
        >
          Ремонт
        </button>
      </div>
      <div class="sub-header__right flex">
        <button
          v-if="$route.params.type === 'new-transport'"
          class="sub-header__box"
          @click="$router.go('-1')"
        >
          Назад
        </button>
      </div>
    </div>
    <div v-if="dateShow || exportDate" class="main-close" @click="dateShow = false" />
    <hr v-if="$route.params.type === 'car-park'">
    <div class="sub-header__wrap_container container" v-if="$route.params.type === 'car-park'">
      <ul class="filter__items flex" style="justify-content: space-evenly">
        <li class="filter__item sub-header__search-block">
          <input
            v-model="search"
            class="filter__item-input"
            type="text"
            placeholder="Поиск"
            v-on:keyup.enter="filterDatabase()"
            @focusout="filterDatabase()"
          />

        </li>
        <li class="filter__item sub-header__search-block">
          <b-form-select
            v-model="car_type"
            class="filter__item-select main__select select-map"
            :options="listTypes"
            text-field="title"
            value-field="sys_name"
            @input="filterDatabase()"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li v-if="$route.params.id === 'transports'" class="filter__item sub-header__search-block">
          <b-form-select
            v-model="city_id"
            class="filter__item-select main__select select-map"
            :options="listCities"
            text-field="name"
            value-field="id"
            @input="filterDatabase()"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li v-if="$route.params.id === 'transports' || $route.params.id === 'repair'" class="filter__item sub-header__search-block">
          <b-form-select
            v-model="status"
            class="filter__item-select main__select"
            :options="$route.params.id === 'transports' ? listStatus : listRepairStatus"
            text-field="title"
            value-field="sys_name"
            @change="filterDatabase()"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li v-if="$route.params.id === 'insurance'" class="filter__item sub-header__search-block">
          <button class="filter__item-btn" @click="dateShow = !dateShow">
            Дата окончания
            <img
              class="filter__item-btn-icon icon-right"
              style="color: #707070; margin-left: 10px"
              src="@/assets/svg/date-picker-icon.svg"
            />
          </button>
          <div v-show="dateShow" class="sub-header__calendar">
            <vc-date-picker
              v-model="date"
              class="add__calendar"
              color="green"
              :rows="1"
              @input="inputData('filter')"
            />
            <button class="btn btn-primary mt-2" @click="hideModal()">Ok</button>
            <button class="btn btn-primary mt-2" @click="inputDataClean('filter')">Очистить</button>
          </div>
        </li>
        <li v-if="$route.params.id === 'insurance'" class="filter__item sub-header__search-block"></li>
        <li v-if="$route.params.id === 'repair'" class="filter__item sub-header__search-block" style="min-width: 120px"></li>
        <li class="filter__item sub-header__search-block right">
          <button class="header__btn right-item default-btn"
            @click="exportDate = !exportDate"
          >
            <img src="@/assets/svg/export.svg" alt="">
            Экспорт данных
          </button>
          <div v-show="exportDate" class="sub-header__calendar" style="left: 0">
            <vc-date-picker
                v-model="exportRange"
                class="add__calendar"
                color="green"
                :rows="1"
                is-range
                @input="inputData('export')"
            />
            <button class="btn btn-primary mt-2" :disabled="this.genButton" @click="exportData">Сформировать</button>
            <button class="btn btn-secondary mt-2" @click="inputDataClean('export')">Отмена</button>
          </div>
          <button v-if="$route.params.id === 'repair'" 
            class="header__btn right-item default-btn" 
            style="margin-left: 25px;"
            @click="changeShowModal()"
          >
            <img src="@/assets/svg/make_repair.svg" alt="">
            Провести ремонт
          </button>
        </li>
        
      </ul>

    </div>

    <filter-purchases v-if="$route.params.type === 'purchases'" />
    <filter-products v-if="$route.params.type === 'products'" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from 'axios';
export default {
  components: {
    FilterPurchases: () => ({
      component: import("../../../components/filter/filterPurchase"),
    }),
    FilterProducts: () => ({
      component: import("../../../components/filter/filterProducts"),
    }),
    purchaseModal: () => ({
      component: import("@/components/add/purchase/purchaseModal"),
    }),
  },
  data() {
    return {
      title: "Автопарк",
      showBlock: false,
      dateShow: false,
      perPage: 10,
      search: "",
      type: "",
      exportDate: false,
      exportRange: {},
      genButton: true,
      date: null,
      status: null,
      city_id: null,
      car_type: null,
      listTypes: [
        {
          sys_name: null,
          title: 'Вид автомобиля',
        },
        {
          sys_name: 'minivan',
          title: 'Минивэн',
        },
        {
          sys_name: 'fura',
          title: 'Фура',
        },
        {
          sys_name: 'hatchback',
          title: 'Хэтчбек',
        },
        {
          sys_name: 'sprinter',
          title: 'Спринтер',
        },
        {
          sys_name: 'trailer',
          title: 'Прицеп',
        },
      ],
      listStatus: [
        {
          sys_name: null,
          title: 'Статус',
        },
        {
          sys_name: 'free',
          title: 'Свободен',
        },
        {
          sys_name: 'busy',
          title: 'Занят',
        },
        {
          sys_name: 'pending',
          title: 'Ожидание',
        },
        {
          sys_name: 'rent',
          title: 'Аренда',
        },
        {
          sys_name: 'repair',
          title: 'Ремонт',
        },
        {
          sys_name: 'onway',
          title: 'В пути',
        },
      ],
      listRepairStatus: [
        {
          sys_name: null,
          title: 'Статус',
        },
        {
          sys_name: 'awaiting',
          title: 'Ожидает',
        },
        {
          sys_name: 'in_work',
          title: 'В процессе',
        },
        {
          sys_name: 'overdue',
          title: 'Просрочено',
        },
        {
          sys_name: 'finished',
          title: 'Завершен',
        },
      ],
      showModal: false,

    };
  },
  watch: {
    "$route.params.type"(to) {
      this.changeRout(to);
      this.search = "";
      this.$store.commit("textFilter", this.search);
      this.$store.commit("statusFilter", null);
      this.$store.commit("addressFilter", null);
      this.$store.commit("typeFilter", null);
    },
    "$route.params.id"() {
      this.status = null;
      this.city_id = null;
      this.car_type = null;
      this.date = null;
      this.search = null;
      this.$store.commit("textFilter", null);
      this.$store.commit("statusFilter", null);
      this.$store.commit("addressFilter", null);
      this.$store.commit("typeFilter", null);
      this.$store.commit("dateFilter", null);
    },
  },
  mounted() {
    this.changeRout(this.$route.params.type);
  },
  methods: {
    changeRout(n) {
      switch (n) {
        case "car-park":
          (this.title = "Автопарк"), (this.showBlock = true);
          break;
        case "info-transport":
          (this.title = "Информация о транспорте"), (this.showBlock = false);
          break;
        case "new-transport":
          (this.title = "Добавление нового транспорта"), (this.showBlock = false);
          break;
        case "drivers":
          (this.title = "Водители"), (this.showBlock = true);
          break;
        case "expeditors":
          (this.title = "Экспедиторы"), (this.showBlock = true);
          break;
        case "counterparties":
          (this.title = "Контерагенты"), (this.showBlock = true);
          break;
        case "purchases":
          (this.title = "Ремонт и закуп запчастей"), (this.showBlock = false);
          break;
        case "cities":
          (this.title = "Города"), (this.showBlock = true);
          break;
        case "regions":
          (this.title = "Направления"), (this.showBlock = true);
          break;
        case "managers":
          (this.title = "Пользователи"), (this.showBlock = true);
          break;
        case "products":
          (this.title = "Запасные части"), (this.showBlock = false);
          break;
        default:
          this.title = "Контерагенты";
          break;
      }
    },
    filterDatabase() {
      this.$store.commit("textFilter", this.search);
      this.$store.commit("statusFilter", this.status);
      this.$store.commit("addressFilter", this.city_id);
      this.$store.commit("typeFilter", this.car_type);
      this.$store.commit("dateFilter", this.date);
    },
    onChangeType() {
      this.$store.commit("contragentType", this.type);
    },
    inputData(type) {
      if (type === 'filter') {
        this.date = new Date(this.date).toISOString('ru-RU').split("T")[0];
        this.$store.commit("dateFilter", this.date);

        // переводим данные в ссылку
        const searchURL = new URL(window.location);

        searchURL.searchParams.set('expired_at', this.date);

        window.history.pushState({}, '', searchURL)
      } else {
        if (this.exportRange) {
          this.exportRange.start = new Date(this.exportRange.start).toISOString().split("T")[0];
          this.exportRange.end = new Date(this.exportRange.end).toISOString().split("T")[0];
          this.$store.commit("exportFilter", this.exportRange);
          this.genButton = false;
        }
      }
    },
    inputDataClean(type) {
      if (type === 'filter') {
        this.$store.commit("dateFilter", null);
        this.hideModal();

        // убираем из ссылки
        const searchURL = new URL(window.location);
        searchURL.searchParams.delete('expired_at');
        window.history.pushState({}, '', searchURL)
      } else {
        this.exportRange = {}
        this.$store.commit("exportFilter", this.exportRange);
        this.exportDate = false;
        this.genButton = true;
      }
    },
    hideModal() {
      this.dateShow = false;
    },
    async exportData() {
      var url = '';
      var params = {};
      var filename = '';
      const parameters = new URLSearchParams(window.location.search);
      const cur_page = parseInt(parameters.get("page"));
      var cur = isNaN(cur_page) ? 1 : cur_page

      url = "/web/transport/export";
      params = {
        from_date: this.exportRange.start,
        to_date: this.exportRange.end,
      };
      filename = 'transports-' + cur;

      if (url.length) {
        await axios({
            method: 'get',
            url : process.env.VUE_APP_API_URL + url,
            params: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            },
            responseType: 'blob'
          }).then(response => {
            const blob = new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            });
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename + '.xlsx');
            if (typeof tempLink.download === 'undefined') {
              tempLink.setAttribute('target', '_blank');
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setTimeout(() => {
              window.URL.revokeObjectURL(blobURL);
            }, 100);
          }).catch(function() {
            console.error('Что-то пошло не так, при экспортировании')
            })
      }
      
    },
    changeShowModal() {
      this.showModal = !this.showModal;
    },
  },
  computed: {
    ...mapState(["citiesProducts", "textFilter", "dateFilter", "typeFilter", "statusFilter", "addressFilter"]),
    listCities() {
      if (this.citiesProducts) {
        return [{ id: null, name: "Город" }, ...this.citiesProducts];
      }
      return [{ id: null, name: "Город" }];
    },
  },
  async created() {
    await this.$store.dispatch("getCitiesProducts", {type: 'only_with_transports'});
  },
};
</script>
<style scoped>
.sub-header {
  padding: 20px 0;
  background-color: #fff;
  border: 1px solid #f2f2f8;
  margin-bottom: 20px;
}
.sub-header__search-block {
  position: relative;
  /* margin: 0px 0 0 38px; */
  height: 40px;
}
.sub-header__search,
.sub-header__select {
  height: 40px;
  margin: 0;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 40px;
}
.sub-header__select {
  padding: 0 10px;
}
.sub-header__search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}
.sub-header__text {
  margin: 0 20px 0 26px;
  width: 80px;
}
.sub-header__title {
  margin-bottom: 10px;
}
.sub-header__box {
  min-width: 36px;
  height: 36px;
  margin-right: 18px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  box-sizing: border-box;
  border-radius: 8px;
}
.sub-header__pogination {
  width: 90px;
  height: 30px;
}
.sub-header__btn-tabs {
  padding: 9px;
  height: 36px;
  background: transparent;
  border-radius: 8px;
  font-weight: bold;
  line-height: 16px;
  outline: none;
}
.sub-header__btn-tabs:not(:last-child) {
  margin-right: 10px;
}
.sub-header__active-tabs {
  background: #e0e9fa;
  color: #306cdc;
}
.car-tabs {
  margin-left: -50px;
}
.filter__items {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
  min-width: 240px;
}
.filter__item-select{
  border-radius: 8px;
  border: 1px solid #e0e9fa;
}
.filter__item-input{
  height: 35px;
  margin: 0;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 20px;
  margin-left: 10px;
  min-width: 260px;
  font-size: 14px;
}
.filter__item-btn {
  height: 35px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  color: #707070;
  text-align: start;
  position: relative;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.filter__item-btn-icon {
  right: 3px;
}

.filter-btn {
  position: relative;
  margin-left: 30px;
  margin-top: 10px;
}

.filter-btn__icon {
  margin-right: 12px;
}
.add__calendar {
  position: static;
  background: #fff;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  top: 45px;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.custom-select {
  font-size: 14px;
}
.default-btn
{
  height: 35px;
  font-size: 14px;
}
.modal-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  transform: translateY(0%);
}
.modal-container {
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 20px;
  max-width: 100%;
  min-width: 300px;
  z-index: 12;
}
</style>
